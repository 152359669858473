import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger'
import rootReducer from '../reducers';

const loggerMiddleware = createLogger()

export const store = createStore(
    rootReducer,
    {
        app: {
            theme: 'light',
            headers: true,
            table_html: false,
            table_css: '<style></style>'
        },
        table: {
            text: '', rows: []
        }
    },
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
)