import React from 'react';
import { connect } from 'react-redux';

// Actions
import { appActions } from '../actions'
import { tableActions } from '../actions'

// Components
import { Header } from '../components/Header'
import { Table } from '../components/Table'
import { Editor } from '../components/Editor'

import ReactGA from 'react-ga';
const trackingId = "UA-10262061-4"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
// ReactGA.set({})
ReactGA.pageview(window.location.pathname + window.location.search);

class Home extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            drag: false,
            resizing: false,
            lwidth: 50
        };
        // Bindings
        this.handleDragEnter    = this.handleDragEnter.bind(this);
        this.handleDragLeave    = this.handleDragLeave.bind(this);
        this.handleDrop         = this.handleDrop.bind(this);
        this.setResizing        = this.setResizing.bind(this);
        this.resizeMove         = this.resizeMove.bind(this);
        this.thanksModal        = this.thanksModal.bind(this);
    }

    handleDragEnter(e){
        e.preventDefault()
        this.setState({ drag: true })
    }
    handleDragLeave(e){
        e.preventDefault()
        this.setState({ drag: false })
    }

    handleDrop(e){
        e.preventDefault();
        let self = this;
        const { dispatch } = this.props;
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < e.dataTransfer.items.length; i++) {
              // If dropped items aren't files, reject them
              if (e.dataTransfer.items[i].kind === 'file') {
                // Capture the file
                var file = e.dataTransfer.items[i].getAsFile();
                // Read the file
                let reader = new FileReader();
                reader.onload = function(e) {
                    const content = e.target.result;
                    self.setState({ drag: false })
                    dispatch( tableActions.update(content) )
                    // Send event to GA
                    ReactGA.event({
                        category: "User",
                        action: 'DropCSV',
                        value: content.length
                    });
                }
                reader.readAsText(file);
              }
            }
        }
    }

    setResizing(mode){
        this.setState({ resizing: mode })
    }

    resizeMove(e){
        if( !this.state.resizing )
            return;
        this.setState({ lwidth: e.clientX/window.innerWidth * 100 })
    }

    thanksModal(e){
        const { dispatch } = this.props;
        dispatch(appActions.update({ notify_copy: false }))
    }

    setAppSettings(setting){

        const { dispatch } = this.props;
        dispatch(appActions.update(setting))

        // Send event to GA
        const key = Object.keys( setting );
        ReactGA.event({
            category: "Settings",
            action: key[0],
            label: setting[key[0]]
        });

    }

    clickEvent(action, value){
        ReactGA.event({
            category: "User",
            action: action,
            label: value
        });
    }

    render() {

        const { app, text } = this.props;
        const theme = app.theme;

        const { drag, lwidth } = this.state;
        const dragClass = drag || text.length === 0 ? 'dragging' : '';

        return <div className={'app-container ' + dragClass + ' ' + theme}>

            <Header />
            
            <div className="body-container" onMouseMove={ this.resizeMove } onMouseUp={ () => this.setResizing(false) }>
                <div 
                    className="float-left bg-dark" 
                    style={{ position: 'relative', height: '100vh', width: `${lwidth}%` }}>

                    <div className="editor-wrap" 
                        onDragEnter={ this.handleDragEnter } 
                        onDragOver={ this.handleDragEnter } 
                        onDragLeave={ this.handleDragLeave } 
                        onDrop={ this.handleDrop }>
                        <Editor />
                        <div className="drop-message" onDragEnter={ (e) => e.stopPropagation() }>
                            <p className="lead d-inline-block ml-auto mr-auto text-center pl-3 pr-3">
                                Start <b>typing</b> or <b>drop</b> your CSV file here...
                            </p>
                        </div>
                    </div>

                    <span 
                        className="resizer"
                        onMouseDown={ () => this.setResizing(true) }
                        onMouseUp={ () => this.setResizing(false) }
                        // onMouseLeave={ () => this.setResizing(false) }
                        ></span>
                </div>
                <div 
                    id="table-wrap"
                    className={`float-right ${theme}`}
                    style={{ position: 'relative', height: '100vh', width: `${100-lwidth}%`, padding: '10px', overflow: 'auto' }}>
                    <Table />
                    { text.length === 0 && <div className="d-flex h-100">
                        <div className="align-self-center ml-auto mr-auto">
                            <p className="lead outro text-center pl-3 pr-3">... you will see the results here.</p>
                        </div>
                    </div> }
                </div>
            </div>

            { app.notify_copy && <div className="alert-modal">
                <div className="content">

                    <p className="lead mb-5">
                        Your table was copied to your clipboard ;)
                    </p>

                    <p className="lead mb-5">
                        Liked this tool? Use the power of a <a className="twitter-share-button"
                            data-url="https://tablify.io"
                            data-text="Convert csv to html tables in seconds!"
                            data-via="TablifyIO"
                            href="https://twitter.com/intent/tweet?via=TablifyIO">tweet</a> 🚀 to help us spread the word.
                    </p>

                    {/* <button className="btn btn-lg btn-light" onClick={ () => this.setAppSettings({ notify_copy: false }) }>Just thanks</button> */}
                    <button className="btn btn-md btn-success" onClick={ (e) => {
                        e.preventDefault();
                        this.setAppSettings({ notify_copy: false })
                        this.clickEvent('thankCopy', 'iLoveIt')
                    }}>
                        Thanks, loved it!
                    </button>
                    <br/>
                    <button className="btn btn-md btn-link text-secondary mt-2" onClick={ (e) => {
                        e.preventDefault();
                        this.setAppSettings({ notify_copy: false })
                        this.clickEvent('thankCopy', 'justThanks')
                    }}>
                        Meh, it's ok.
                    </button>

                </div>
            </div> }

            { app.table_html && <div className="alert-modal">
                <div className="content">

                    <p className="lead mb-3">
                        Here's your HTML and some CSS.<br/>
                        Copy it and use it wisely 😉
                    </p>

                    <textarea 
                        className="form-control mb-4"
                        style={{ fontSize: '11px', height: '50vh' }} 
                        defaultValue={ (app.table_html + '\n\n') } />

                    <button className="btn btn-md btn-success" onClick={ (e) => {
                        e.preventDefault();
                        this.setAppSettings({ table_html: false })
                        this.clickEvent('thankHTML', 'iLoveIt')
                    }}>
                        Thanks, loved it!
                    </button>
                    <br/>
                    <button className="btn btn-md btn-link text-secondary mt-2" onClick={ (e) => {
                        e.preventDefault();
                        this.setAppSettings({ table_html: false })
                        this.clickEvent('thankHTML', 'justThanks')
                    }}>
                        Meh, it's ok.
                    </button>

                </div>
            </div> }

        </div>
    }

}

function mapStateToProps(state) {
    const { app, table } = state;
    return {
        app,
        text: table.text
    };
}

const connectedHome = connect(mapStateToProps)(Home);
export { connectedHome as Home };