import { appConstants } from '../constants';

export const appActions = {
    update,
    trackStat
};

function update(data) {

    return { type: appConstants.UPDATE, data: data }

    // function request(user) { return { type: tableConstants.LOGIN_REQUEST, user } }
    // function success(user) { return { type: tableConstants.LOGIN_SUCCESS, user } }
    // function failure(error) { return { type: tableConstants.LOGIN_FAILURE, error } }
}

function trackStat(data) {

    return dispatch => {
        fetch('/api/stats', {
            method: 'POST',
            headers:{ 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
        .then( res => res.json() )
        .then( res => dispatch({ type: appConstants.UPDATE, data: data }) )
    };

    // function request(user) { return { type: tableConstants.LOGIN_REQUEST, user } }
    // function success(user) { return { type: tableConstants.LOGIN_SUCCESS, user } }
    // function failure(error) { return { type: tableConstants.LOGIN_FAILURE, error } }
}