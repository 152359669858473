import React from 'react';
import { Route, Router } from 'react-router-dom';

// Pages
import {Home} from './pages/index';
import Contact from './pages/contact';

// Helpers
import { history } from './helpers/history';

// CSS
import './App.css';

function App() {

  return (
    <div className="App">

      <Router history={ history } >

        <Route exact path="/" component={ Home } />
        <Route exact path="/contact" component={ Contact } />

      </Router>
      
    </div>
  );

}

export default App;
