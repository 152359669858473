import React from 'react'
import './Looper.css';

class Looper extends React.Component {

    constructor(props) {
        super(props);
        // Default state
        this.state = {
            clickable: this.props.clickable || false,
            messages: this.props.messages || [],
            current: 0
        };
        // Bindings
        this.next        = this.next.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount(){
        setInterval( this.next, 5000 );
    }

    next(){
        const next = this.state.current === this.state.messages.length-1 ? 0 : this.state.current+1;
        this.setState({ current: next })
    }

    handleClick(e){
        if( !this.state.clickable )
            return;
        this.next();
    }

    render(){

        const { messages, current } = this.state;

        const messagesLines = messages.map( (m, mi) => {
            const statusClass   = mi === current ? 'current' : '';
            return <span 
                key={`m-${mi}`} 
                className={`message m${mi} ${statusClass}`} 
                onClick={ this.handleClick }>{ m }</span>
        })

        return <div className={`looper msg${current}`}>
            { messagesLines }
        </div>
    }

}

export { Looper }