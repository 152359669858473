import { tableConstants } from '../constants';

export const tableActions = {
    update,
    updateCell
};

function update(data) {
    // data = data.trim();
    const rt = data.length > 0 ? data.split('\n').map( l => l.split(',') ) : []
    return { type: tableConstants.UPDATE, text: data, rows: rt };

    // function request(user) { return { type: tableConstants.LOGIN_REQUEST, user } }
    // function success(user) { return { type: tableConstants.LOGIN_SUCCESS, user } }
    // function failure(error) { return { type: tableConstants.LOGIN_FAILURE, error } }
}

function updateCell(row, col, data) {

    // let rows = [...state.rows]
    // let rowCol = [...rows[action.row]]
    // rowCol[action.col] = action.text
    // rows[action.row] = rowCol

    return { type: tableConstants.UPDATE, text: '', rows: [] };

    // function request(user) { return { type: tableConstants.LOGIN_REQUEST, user } }
    // function success(user) { return { type: tableConstants.LOGIN_SUCCESS, user } }
    // function failure(error) { return { type: tableConstants.LOGIN_FAILURE, error } }
}