import React from 'react'
import { connect } from 'react-redux';

import { tableActions } from '../../actions'

class Editor extends React.Component {

    constructor(props) {
        super(props);
        // Default state
        this.state = {
            value: ''
        };
        // Bindings
        // this.handleClick   = this.handleClick.bind(this);
        // this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange  = this.handleChange.bind(this);
    }

    componentDidMount(){
        if(this.props.autoFocus)
            this.nameInput.focus(); 
    }

    handleChange(e){
        const { dispatch } = this.props;
        // this.setState({ value: e.target.value })
        dispatch( tableActions.update(e.target.value) )
    }

    render(){
        const { text } = this.props;
        return <textarea 
            id="tably-editor" 
            className="editor form-control"
            onChange={ this.handleChange }
            value={ text } 
            />
    }

}

function mapStateToProps(state) {
    const { text } = state.table;
    return {
        text
    };
}

const connectedEditorPage = connect(mapStateToProps)(Editor);
export { connectedEditorPage as Editor }; 