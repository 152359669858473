import React from 'react'
import { connect } from 'react-redux';

import { Switchy } from '../UI/Switchy';
import { Looper } from '../UI/Looper';

import { appActions } from '../../actions'

import ReactGA from 'react-ga';
const trackingId = "UA-10262061-4"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

class Header extends React.Component {

    constructor(props) {
        super(props);
        // Default state
        this.state = {};
        // Bindings
        this.copyTable = this.copyTable.bind(this);
    }

    setTheme = (v) => {
        const { dispatch } = this.props;
        const theme = v.value;
        dispatch(appActions.update({ theme }))
        // Send event to GA
        ReactGA.event({
            category: "User",
            action: 'SetTheme',
            label: theme
        });
    }

    setHeaders = (v) => {
        const { dispatch } = this.props;
        const headers = v.value;
        dispatch(appActions.update({ headers }))
        // Send event to GA
        ReactGA.event({
            category: "User",
            action: 'SetHeaders',
            label: headers+''
        });
    }

    copyTable = (e) => {
        const { dispatch } = this.props;
        const selection = document.getSelection();
        const range = document.createRange();
        const tably = document.getElementById('tably');

        selection.removeAllRanges();
        range.selectNodeContents( tably )
        selection.addRange(range);
        const success = document.execCommand("copy");
        if(success){
            dispatch(appActions.update({ notify_copy: true }))
            // Send event to GA
            ReactGA.event({
                category: "User",
                action: 'copyTable',
                label: 'true'
            });
        }
        selection.removeAllRanges();
    }

    getHTML = (e) => {
        const { dispatch } = this.props;
        const tably = document.getElementById('tably');
        const parent = tably.parentNode;
        // console.log(parent.innerHTML)
        dispatch(appActions.update({ table_html: parent.innerHTML }))
        // Send event to GA
        ReactGA.event({
            category: "User",
            action: 'GetHTML',
            label: 'true'
        });
    }

    render(){

        const { table } = this.props;

        return <nav className="navbar navbar-expand">

            <span className="navbar-brand text-white">Tablify</span>
            <span className="navbar-text d-none d-md-inline-block">
                <Looper messages={[
                    <span>
                        Fast <code>csv</code> to <code>table</code> converter &nbsp;&nbsp;
                        <a className="twitter-share-button"
                            data-url="https://tablify.io"
                            data-text="Convert csv to html tables in seconds!"
                            data-via="TablifyIO"
                            href="https://twitter.com/intent/tweet">Tweet</a>
                    </span>,
                    // <span>Like this tool? <a className="twitter-share-button"
                    //     href="https://twitter.com/intent/tweet?text=Hello%20world">Tweet</a></span>
                    // <span>101,223 <span role="img" aria-label="check">🚀</span>tables generated</span>,
                    // <span>812 people have thanked <span role="img" aria-label="love">❤️</span></span>
                ]} />
            </span>
            {/* <span class="navbar-text">
                Coded with love by @davoscript. <a className="nav-link" href="/">Here's why.</a>
            </span> */}

            {/* Only visible on mobile */}
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button> */}

            {/* <div className="collapse navbar-collapse">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        Coded with love by @davoscript. <a className="nav-link" href="/">Here's why.</a>
                    </li>
                </ul>
            </div> */}

            <ul className="navbar-nav ml-auto">
                { table.rows.length > 0 && <li className="nav-item ml-4">
                    <button className="btn btn-primary" style={{ borderRadius: '20px' }}
                        onClick={ this.copyTable }>Copy</button>
                </li> }
                { table.rows.length > 0 && <li className="nav-item ml-4 d-none d-md-inline-block">
                    <button className="btn btn-primary" style={{ borderRadius: '20px' }}
                        onClick={ this.getHTML }>HTML</button>
                </li> }
                <li className="nav-item ml-4">
                    <Switchy 
                        values={[ { label: 'Light', value: 'light' }, { label: 'Dark', value: 'dark' } ]}
                        onSelect={ this.setTheme } />
                </li>
                <li className="nav-item ml-4 d-none d-md-inline-block">
                    <Switchy 
                        values={[ { label: 'Bold', value: true }, { label: 'Text', value: false } ]}
                        onSelect={ this.setHeaders } />
                </li>
            </ul>

        </nav>
    }

}

function mapStateToProps(state) {
    const { app, table } = state;
    return {
        app,
        table
    };
}

const connectedHeaderPage = connect(mapStateToProps)(Header);
export { connectedHeaderPage as Header }; 