import React from 'react'
import './Switchy.css';

class Switchy extends React.Component {

    constructor(props) {
        super(props);
        // Default state
        this.state = {
            values: this.props.values || [],
            selected: 0
        };
        // Bindings
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, vi, v){
        this.setState({ selected: vi })
        if( this.props.onSelect )
            this.props.onSelect(v)
    }

    render(){

        const { values, selected } = this.state;

        const options = values.map( (v, vi) => {
            const statusClass = vi === selected ? 'selected' : '';
            return <span key={`op-${vi}`} className={`option op${vi} ${statusClass}`} onClick={ (e) => this.handleClick(e, vi, v) }>{ v.label }</span>
        })

        return <div className={`switchy op${selected}`}>
            {options}
            <span className="slider"></span>
        </div>
    }

}

export { Switchy }