import { appConstants } from '../constants/app';

export function app(state = {}, action) {
  switch (action.type) {
    case appConstants.UPDATE:
        const data = action.data;
        return {...state, ...data}
    default:
      return state
  }
}