import React from 'react'
import { connect } from 'react-redux';

// Actions
// import { tableActions } from '../../actions'

// Components
// import { Input } from './Input'

class Table extends React.Component {

    constructor(props) {
        super(props);
        // Default state
        this.state = {
            head: [],
            rows: [
                { tds: ['', '', ''] }
            ],
            focus: [-1, -1],
            edit: false
        };
        // Bindings
        this.moveUp             = this.moveUp.bind(this)
        this.moveRight          = this.moveRight.bind(this)
        this.moveDown           = this.moveDown.bind(this)
        this.moveLeft           = this.moveLeft.bind(this)
        this.handleReturn       = this.handleReturn.bind(this)
        this.handleTab          = this.handleTab.bind(this)
        this.handleKeyDown      = this.handleKeyDown.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);
    }

    componentDidUpdate( prevProps, prevUpdate ){}

    handleInputChange(row, col, value){
        // const { dispatch } = this.props;
        // dispatch( tableActions.updateCell(row, col, value) )
    }

    moveUp(e){
        let focus = [...this.state.focus]
        focus[1] = focus[1] > 0 ? focus[1] - 1 : focus[1]
        this.setState({ focus })
    }

    moveDown(e){
        this.handleReturn(e)
    }

    moveRight(e){
        if( !this.state.edit )
            this.handleTab(e)
    }

    moveLeft(e){
        if( !this.state.edit ){
            let focus = [...this.state.focus]
            focus[0] = focus[0] > 0 ? focus[0] - 1 : focus[0]
            this.setState({ focus })
        }
    }

    handleKeyDown(e){
        console.log(e.keyCode)
        // switch(e.keyCode){
        //     case 40:
        //         this.moveDown(e)
        //         break;
        //     case 38:
        //         this.moveUp(e)
        //         break;
        //     case 37:
        //         this.moveLeft(e)
        //         break;
        //     case 39:
        //         this.moveRight(e)
        //         break;
        //     case 13:
        //         console.log('Return')
        //         this.handleReturn(e)
        //         break;
        //     default:
        //         break;
        // }
    }

    setFocus(x, y){
        x = x === this.state.focus[0] ? -1 : x;
        y = y === this.state.focus[1] ? -1 : y;
        this.setState({ focus: [x, y] })
    }

    addRow(){
        // Add row
        let rows = [...this.state.rows]
        const newTDs = rows[rows.length-1].tds.map( d => '' )
        rows.push({ tds: newTDs })
        this.setState({ rows })
    }

    addCol(){
        let rows = [...this.state.rows]
        rows = rows.map( r => { 
            r.tds.push('') 
            return r
        })
        this.setState({ rows })
    }

    handleReturn(e){
        this.setState({ edit: !this.state.edit }, () => {
            this.nameTable.focus()
        })
        // const focus = this.state.focus

        // if( focus[1] === this.state.rows.length-1 ){
        //     this.addRow()
        // }

        // // Set focus
        // this.setFocus(focus[0], focus[1]+1)
    }

    handleTab(e){
        const focus = this.state.focus
        const cols = this.state.rows[0].tds.length

        if( this.state.focus[0] === cols-1 ){
            this.addCol()
            e.preventDefault()
        }

        this.setFocus(focus[0]+1, focus[1])
    }

    render(){

        const { app, table } = this.props;

        const focus = this.state.focus
        // const edit = this.state.edit

        const maxLength = Math.max.apply(null, table.rows.map( r => r.length ))
        const bodyRows = table.rows.map( (row, ri) => {
            const bodyData = (Array.apply(null, Array(maxLength))).map( (datacol, di) => {
                const data = row[di] ? (row[di]).trim() : '';
                const focused = di === focus[0] && ri === focus[1] ? true : false;
                return <td key={`td-${di}`} className={ focused ? 'focused' : '' } 
                            // onClick={ () => this.setFocus(di, ri) }
                            >
                    { ri === 0 && app.headers && <b className="tably-input">{data}</b> }
                    { (ri > 0 || !app.headers) && <span className="tably-input">{data}</span> }
                    {/* <Input 
                        value={ data } 
                        onChange={ (value) => this.handleInputChange(ri, di, value) }
                        onClick={ () => this.setFocus(di, ri) }
                        onReturn={ this.handleReturn }
                        onTab={ this.handleTab }
                        onKeyDown={ this.handleKeyDown }
                        autoFocus={ focused && edit }
                        /> */}
                </td>})
            return <tr key={`tr-${ri}`}>{ bodyData }</tr>
        })

        return <table 
            id="tably"
            ref={(table) => { this.nameTable = table; }}
            className="tably" 
            // onKeyDown={ this.handleKeyDown }
            >
            <tbody>
                { bodyRows }
            </tbody>
        </table>
    }

}

function mapStateToProps(state) {
    const { app, table } = state;
    return {
        app,
        table
    };
}

const connectedTable = connect(mapStateToProps)(Table);
export { connectedTable as Table }; 
