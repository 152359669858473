import { tableConstants } from '../constants/table';

export function table(state = { text: '', rows: [] }, action) {
  switch (action.type) {
    case tableConstants.CLEAR:
      return {};
    case tableConstants.UPDATE:
      return {...state, text: action.text, rows: action.rows}
    default:
      return state
  }
}