import React from 'react';
import { render } from 'react-dom';

// Redux!
import { Provider } from 'react-redux';
import { store } from './helpers/store' 

import * as serviceWorker from './serviceWorker';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Main Component
import App from './App.jsx';

render((
    <Provider store={ store }>
        <App />
    </Provider>), 
    document.getElementById('root') );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
