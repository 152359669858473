import React from 'react';

class Contact extends React.Component {

    render() {
        return <div>
            Contact info
        </div>
    }

}

export default Contact