import { combineReducers } from 'redux'

// Reducers
import { app } from '../reducers/app'
import { table } from '../reducers/table'

const rootReducer = combineReducers({
    app,
    table
})

export default rootReducer